import logo from "./assets/img/logo.svg";
import pinksale from "./assets/img/pinksale.svg";
import uniswap from "./assets/img/uni.svg";
import tg from "./assets/img/tg.svg";
import tw from "./assets/img/tw.svg";
import ut1 from "./assets/img/ut-1.svg";
import ut2 from "./assets/img/ut-2.svg";
import ut3 from "./assets/img/ut-3.svg";
import roadmap from "./assets/img/roadmap.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="bg-[url('./assets/img/bg-1.png')] bg-no-repeat bg-cover min-h-screen bg-center relative">
        <div className="bg-[#00000040]">
          <div className="container mx-auto px-16 py-5 flex flex-col gap-5 items-center justify-between lg:flex-row">
            <div className="max-w-[150px]">
              <img src={logo} alt="" />
            </div>
            <ul className="flex gap-8 lg:gap-16">
              <li>
                <a>Home</a>
              </li>
              <li>
                <a href="#about">About Us</a>
              </li>
              <li>
                <a href="#roadmap">Roadmap</a>
              </li>
            </ul>
            <a
              className="btn-primary"
              href="#"
              target="_blank"
              rel="noopener noreferrer">
              Open App
            </a>
          </div>
        </div>
        <div className="text-center max-w-4xl px-5 mx-auto absolute bottom-24 left-0 right-0">
          <h1 className="uppercase text-6xl text-[#FCF518] xl:7xl">
            Velociraptor Twitter
          </h1>
          <p className="my-8 text-xl">
            A unique Meme Coin ecosystem that combines King Velociraptor's power
            with Twitter's virality in the Crypto world.
          </p>
          <div className="max-w-3xl mx-auto flex flex-col gap-5 justify-center items-center lg:flex-row lg:max-w-3xl">
            <a
              className="uppercase btn-primary w-full lg:w-auto"
              href="https://www.pinksale.finance/launchpad/0xe9Ce9EC1D5787A21bA05983468F600507C4922E3?chain=ETH"
              target="_blank"
              rel="noopener noreferrer">
              <img src={pinksale} alt="" />
              <span>Buy on pinksale</span>
            </a>
            <a
              className="uppercase btn-primary w-full lg:w-auto"
              href="https://app.uniswap.org/#/swap?outputCurrency=0x9f9260b60C98e9D0d7f931F1541768614Bd6CB6f"
              target="_blank"
              rel="noopener noreferrer">
              <img src={uniswap} alt="" />
              <span>Trade on uniswap</span>
            </a>
          </div>
        </div>
      </header>

      <main>
        <section
          id="about"
          className="bg-[url('./assets/img/bg-2.png')] bg-no-repeat bg-cover py-[100px]">
          <div className="w-full mx-auto px-0 py-[50px] bg-[url('./assets/img/bg-2-1.png')] bg-center bg-no-repeat bg-cover xl:px-16 xl:container xl:bg-top xl:py-[200px]">
            <div className="py-[150px] px-5">
              <div className="text-center max-w-5xl mx-auto">
                <h2 className="text-primary text-5xl">ABOUT US</h2>
                <p className="mt-10 text-text text-xl tracking-wide">
                  The blockchain ecosystem thrives on community-driven
                  innovation and improvement, driven by the collective wisdom
                  and enthusiasm of the community.
                  <br />
                  <br />
                  Combining King Velociraptor's power with Twitter's virality in
                  the Crypto world is a groundbreaking platform that leverages
                  the captivating appeal of Meme Coin and the influential reach
                  of social media.
                  <br />
                  <br />
                  By creating a dynamic and engaging environment, we offer
                  unique utilities such as Memetic Trading, seamless social
                  network integration, Velociraptor governance, meme rewards,
                  and community-driven development. Through the ecosystem, meme
                  coin enthusiasts can participate in a thriving community,
                  foster creativity, and potentially benefit from the financial
                  growth of Meme Coins.
                  <br />
                  <br />
                  <br />
                  <span className="text-2xl">
                    Contract: 0x9f9260b60C98e9D0d7f931F1541768614Bd6CB6f
                  </span>
                  <p className="text-2xl">
                    Contact: velociraptortwitter@gmail.com
                  </p>
                </p>
                <div className="mt-10 flex gap-5 justify-center items-center">
                  <a
                    className="btn-primary"
                    href="https://app.uniswap.org/#/swap?outputCurrency=0x9f9260b60C98e9D0d7f931F1541768614Bd6CB6f"
                    target="_blank"
                    rel="noopener noreferrer">
                    <span>Buy</span>
                  </a>
                  <a
                    className="btn-primary"
                    href="https://app.uniswap.org/#/swap?outputCurrency=0x9f9260b60C98e9D0d7f931F1541768614Bd6CB6f"
                    target="_blank"
                    rel="noopener noreferrer">
                    <span>Chart</span>
                  </a>
                </div>
              </div>
              <div className="text-center mt-[200px]">
                <h2 className="text-primary text-5xl">UTILITIES</h2>
                <div className="grid grid-cols-1 max-w-5xl px-5 mx-auto mt-[100px] gap-16 lg:gap-10 lg:grid-cols-3">
                  <div className="bg-[url('./assets/img/bg.png')] bg-cover bg-no-repeat px-8 py-16 flex flex-col mx-auto justify-center items-center ut relative">
                    <h3 className="text-3xl">MEMETIC TRADING</h3>
                    <p className="text-lg mt-5">
                      Trading uses transactions based on market trends and
                      sentiment
                      <br />
                      <br />
                      Users can engage in Meme-inspired trading activities,
                      utilizing the power of viral trends and humorous content
                      to drive value and create unique trading opportunities
                    </p>
                    <div className="absolute w-[80px] top-[-40px]">
                      <img src={ut1} alt="" className="w-full" />
                    </div>
                  </div>
                  <div className="bg-[url('./assets/img/bg.png')] bg-cover bg-no-repeat px-8 py-16 flex flex-col  mx-auto justify-center items-center ut relative">
                    <h3 className="text-3xl">STAKE TO EARN</h3>
                    <p className="text-lg mt-5">
                      Hold and lock tokens in the staking system to earn passive
                      rewards
                      <br />
                      <br />
                      Active participation and contribution to the ecosystem are
                      incentivized through rewards. People who stake their
                      tokens on the staking system can earn more bonus tokens,
                      incentivizing community activity and interaction
                    </p>
                    <div className="absolute w-[80px] top-[-40px]">
                      <img src={ut2} alt="" className="w-full" />
                    </div>
                  </div>
                  <div className="bg-[url('./assets/img/bg.png')] bg-cover bg-no-repeat px-8 py-16 flex flex-col mx-auto justify-center items-center relative ut">
                    <h3 className="text-3xl">VELOCIRAPTOR GOVERNANCE</h3>
                    <p className="text-lg mt-5">
                      Inspired by the authority of the King to make decisions
                      for the community
                      <br />
                      <br />
                      King Velociraptor's power extends to the governance
                      mechanisms of the ecosystem, where token holders can
                      participate in voting and decision-making processes,
                      ensuring a fair and transparent system
                    </p>
                    <div className="absolute w-[80px] top-[-40px]">
                      <img src={ut3} alt="" className="w-full" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="roadmap"
          className="bg-[url('./assets/img/bg-2-2.png')] bg-no-repeat bg-cover py-[50px] lg:py-[100px]">
          <div className="text-center max-w-5xl mx-auto py-16">
            <h2 className="text-[#FCF518] text-5xl pt-16">ROADMAP</h2>
            <div className="grid grid-cols-1 max-w-4xl mx-auto mt-[100px] gap-16 lg:gap-5 lg:grid-cols-3">
              <div className="bg-[url('./assets/img/bg.png')] bg-cover bg-no-repeat px-8 py-16 flex flex-col mx-auto justify-center items-center ut relative w-[280px]">
                <h3 className="text-3xl">PHASE 1</h3>
                <ul className="text-lg mt-5">
                  <li>Security Audit</li>
                  <li>Start marketing plan</li>
                  <li>Community building</li>
                  <li>Presale start</li>
                  <li>Promote marketing</li>
                  <li>CMC, CGK listing</li>
                </ul>
                <div className="absolute w-[80px] top-[-40px]">
                  <img src={roadmap} alt="" className="w-full" />
                </div>
              </div>
              <div className="bg-[url('./assets/img/bg.png')] bg-cover bg-no-repeat px-8 py-16 flex flex-col  mx-auto justify-center items-center ut relative w-[280px]">
                <h3 className="text-3xl">PHASE 2</h3>
                <ul className="text-lg mt-5">
                  <li>Token launch</li>
                  <li>Dextools trending</li>
                  <li>500 heavenly holders</li>
                  <li>Holders giveaways campaign</li>
                  <li>DApp development</li>
                  <li>More exchange listing</li>
                </ul>
                <div className="absolute w-[80px] top-[-40px]">
                  <img src={roadmap} alt="" className="w-full" />
                </div>
              </div>
              <div className="bg-[url('./assets/img/bg.png')] bg-cover bg-no-repeat px-8 py-16 flex flex-col mx-auto justify-center items-center relative ut w-[280px]">
                <h3 className="text-3xl">PHASE 3</h3>
                <ul className="text-lg mt-5">
                  <li>Whitepaper released</li>
                  <li>2000 heavenly holders</li>
                  <li>DApp on Testnet</li>
                  <li>Community Campaign</li>
                  <li>Pre-marketing</li>
                  <li>Update roadmap</li>
                </ul>
                <div className="absolute w-[80px] top-[-40px]">
                  <img src={roadmap} alt="" className="w-full" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer>
        <div className="container mx-auto px-16 py-10">
          <div className="max-w-5xl mx-auto flex flex-col justify-between items-center pb-10 border-b-[1px] border-b-[#FFF2AE] gap-5 lg:flex-row">
            <span className="text-2xl">JOIN OUR COMMUNITY</span>
            <div className="flex flex-col gap-5 justify-center items-center lg:flex-row">
              <a
                className="uppercase btn-primary"
                href="https://t.me/VelociraptorTwitter"
                target="_blank"
                rel="noopener noreferrer">
                <img src={tg} alt="" />
                <span>@VelociraptorTwitter</span>
              </a>
              <a
                className="uppercase btn-primary"
                href="https://twitter.com/VelociraptorTW"
                target="_blank"
                rel="noopener noreferrer">
                <img src={tw} alt="" />
                <span>@VelociraptorTW</span>
              </a>
            </div>
          </div>
          <span className="flex justify-center pt-6 uppercase text-center">
            velociraptor twitter Copyright © 2023
          </span>
        </div>
      </footer>
    </div>
  );
}

export default App;
